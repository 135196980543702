// !!IMPORTANT!! Creator dynamic content start

:root {
  --primary-light: #FF5F6D;
  --primary: #EB0A1E;
  --primary-dark: #C50F1F;
  --secondary: #000;
  --state-success: #7DCB61;
  --state-success-dark: #598F45;
  --state-warn: #F9D067;
  --state-warn-dark: #D6A832;
  --state-danger: #DA2222;
  --state-danger-dark: #EB0A1E;
  --base-text: #222;
  --base-link: #222;
  --base-bg: #fbfbfb;
  --base-lightest: #fff;
  --base-lighter: #f9f9f9;
  --base-light: #f0f0f0;
  --base: #000;
  --base-dark: #999;
  --base-darker: #777;
  --base-darkest: #000;
  --base-black: #000;
  --base-white: #FFF;
  --gray-1: #F2F2F2;
  --gray-2: #EAEAEA;
  --gray-3: #D7D7D7;
  --gray-4: #A5A5A5;
  --gray-5: #7C7D80;
  --gray-6: #585958;
  --gray-7: #595a58;
  --blue-1: #00A3B7;
  --blue-2: #397DC0;
  --blue-3: #5DA3B4;
  --blue-4: #0D7594;
}

[data-theme="dark"] {
  --base-text: #f0f0f0;
  --base-bg: #333;
}

@media (prefers-color-scheme: dark) {
  [data-theme="os"] {
  --base-text: #f0f0f0;
  --base-bg: #333;
}
}
// !!IMPORTANT!! End of Creator dynamic content
