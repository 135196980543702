@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.section-wrapper {
  position: relative;
  overflow-x: hidden;

  @include gt-phone {
    overflow-x: initial;
  }

  /* SPACING
  ========================================================================== */
  &[data-spacing='1'] {
    padding: $s-zs 0;
  }

  &[data-spacing='2'] {
    padding: $s-s 0;
  }

  &[data-spacing='3'] {
    padding: $s-md 0;
  }

  &[data-spacing='4'] {
    padding: $s-md 0;

    @include gt-tablet {
      padding: $s-lg 0;
    }
  }

  //UPDATE AND FIX THIS LINE
  &[data-spacing='5'],
  &[data-spacing='6'] {
    padding: $s-lg 0;

    @include gt-tablet {
      padding: $s-xl 0;
    }
  }

  &[data-spacing='7'] {
    padding: $s-xl 0;

    @include gt-tablet {
      padding: $s-xxl 0;
    }
  }

  &[data-spacing='8'] {
    padding: $s-xxl 0;

    @include gt-tablet {
      padding: $s-xxxl 0;
    }
  }

  /* MARGIN TOP
  ========================================================================== */
  &[data-margin-top='0'] {
    margin-top: 0 !important;
  }

  &[data-margin-top='1'] {
    margin-top: $s-zs !important;
  }

  &[data-margin-top='2'] {
    margin-top: $s-s !important;
  }

  &[data-margin-top='3'] {
    margin-top: $s-md !important;
  }

  &[data-margin-top='4'] {
    margin-top: $s-md !important;

    @include gt-tablet {
      margin-top: $s-lg !important;
    }
  }

  &[data-margin-top='5'] {
    margin-top: $s-lg !important;

    @include gt-tablet {
      margin-top: $s-xl !important;
    }
  }

  &[data-margin-top='6'] {
    margin-top: $s-xl !important;

    @include gt-tablet {
      margin-top: $s-xxl !important;
    }
  }

  &[data-margin-top='7'] {
    margin-top: $s-xxl !important;

    @include gt-tablet {
      margin-top: $s-xxxl !important;
    }
  }

  /* MARGIN BOTTOM
  ========================================================================== */
  &[data-margin-top='0'] {
    margin-top: 0 !important;
  }

  &[data-margin-top='1'] {
    margin-top: $s-zs !important;
  }

  &[data-margin-top='2'] {
    margin-top: $s-s !important;
  }

  &[data-margin-top='3'] {
    margin-top: $s-md !important;
  }

  &[data-margin-top='4'] {
    margin-top: $s-md !important;

    @include gt-tablet {
      margin-top: $s-lg !important;
    }
  }

  &[data-margin-top='5'] {
    margin-top: $s-lg !important;

    @include gt-tablet {
      margin-top: $s-xl !important;
    }
  }

  &[data-margin-top='6'] {
    margin-top: $s-xl !important;

    @include gt-tablet {
      margin-top: $s-xxl !important;
    }
  }

  &[data-margin-top='7'] {
    margin-top: $s-xxl !important;

    @include gt-tablet {
      margin-top: $s-xxxl !important;
    }
  }

  /* SPACING TOP
  ========================================================================== */
  &[data-spacing-top='0'] {
    padding-top: 0 !important;
  }

  &[data-spacing-top='1'] {
    padding-top: $s-zs !important;
  }

  &[data-spacing-top='2'] {
    padding-top: $s-s !important;
  }

  &[data-spacing-top='3'] {
    padding-top: $s-md !important;
  }

  &[data-spacing-top='4'] {
    padding-top: $s-md !important;

    @include gt-tablet {
      padding-top: $s-lg !important;
    }
  }

  &[data-spacing-top='5'] {
    padding-top: $s-lg !important;

    @include gt-tablet {
      padding-top: $s-xl !important;
    }
  }

  &[data-spacing-top='6'] {
    padding-top: $s-xl !important;

    @include gt-tablet {
      padding-top: $s-xxl !important;
    }
  }

  &[data-spacing-top='7'] {
    padding-top: $s-xxl !important;

    @include gt-tablet {
      padding-top: $s-xxxl !important;
    }
  }

  /* SPACING BOTTOM
  ========================================================================== */
  &[data-spacing-bottom='0'] {
    padding-bottom: 0 !important;
  }

  &[data-spacing-bottom='1'] {
    padding-bottom: $s-zs !important;
  }

  &[data-spacing-bottom='2'] {
    padding-bottom: $s-s !important;
  }

  &[data-spacing-bottom='3'] {
    padding-bottom: $s-md !important;
  }

  &[data-spacing-bottom='4'] {
    padding-bottom: $s-md !important;

    @include gt-tablet {
      padding-bottom: $s-lg !important;
    }
  }

  &[data-spacing-bottom='5'] {
    padding-bottom: $s-lg !important;

    @include gt-tablet {
      padding-bottom: $s-xl !important;
    }
  }

  &[data-spacing-bottom='6'] {
    padding-bottom: $s-xl !important;

    @include gt-tablet {
      padding-bottom: $s-xxl !important;
    }
  }

  &[data-spacing-bottom='7'] {
    padding-bottom: $s-xxl !important;

    @include gt-tablet {
      padding-bottom: $s-xxxl !important;
    }
  }

  /* EXCEPTIONS
  ========================================================================== */
  &[data-remove-bottom-spacing='true'] {
    padding-bottom: 0;
  }

  &[data-remove-top-spacing='true'] {
    padding-top: 0;
  }
  /* BACKGROUND
  ========================================================================== */
  &[data-bg-color='white'] {
    background-color: $c-base-white;
  }

  &[data-bg-color='black'] {
    background-color: $c-base-black;

    * {
      color: $c-base-white;
    }
  }

  &[data-bg-color='gray'] {
    background-color: $c-gray-1;
  }

  &[data-bg-color='gray-2'] {
    background-color: $c-gray-2;
  }

  &[data-bg-color='gray-3'] {
    background-color: $c-gray-3;
  }

  &[data-bg-color='gray-4'] {
    background-color: $c-gray-4;
  }

  &[data-bg-color='gray-5'] {
    background-color: $c-gray-5;
  }

  &[data-bg-color='gray-6'] {
    background-color: $c-gray-6;
  }

  &[data-bg-color='gray-4'],
  &[data-bg-color='gray-5'],
  &[data-bg-color='gray-6'],
  &[data-bg-color='gray-7'],
  &[data-bg-color='blue-1'],
  &[data-bg-color='blue-2'] {
    * {
      color: $c-base-white;
    }

    a:hover,
    a:focus,
    button:hover,
    button:focus {
      color: $c-base-white;
      opacity: 0.7;
      transition: opacity ease 0.3s;
    }

    [class^='slick-dots'] {
      button {
        background-color: $c-base-white !important;
        color: transparent;
      }

      li {
        button {
          &[class^='slick-active'] {
            background-color: $c-base-white !important;
          }
        }
      }
    }
  }

  &[data-bg-color='gray-7'] {
    background-color: $c-gray-7;
  }

  &[data-bg-color='blue-1'] {
    background-color: $c-blue-1;
  }

  &[data-bg-color='blue-2'] {
    background-color: $c-blue-2;
  }

  /* ELEMENTS DISPOSITION
  ========================================================================== */
  &[data-center-content='true'] {
    * {
      margin-inline: auto !important;
      text-align: center;
    }
    [class^='slick-track'] {
      @include gt-phone {
        display: flex;
        flex-direction: row;
        gap: $s-lg;
      }
    }
  }
}
