@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$height-logo-toyota: 1.8rem;
$everything-that-moves-logo-width: 19.7rem;
$everything-that-moves-logo-height: 8.7rem;
$everything-that-moves-logo-border: 0.6rem;
$everything-that-moves-border-right-position: 3.6rem;
$everything-that-moves-margin-left: 19.9rem;
$height-logo-seal: 3.5rem;

/* FOOTER
========================================================================== */
.footer {
  overflow: hidden;

  &[data-locale='VE'] {
    @supports (-webkit-appearance: none) {
      .everything-that-moves-container:after {
        margin-left: $everything-that-moves-margin-left;
      }
    }

    @supports (-moz-appearance: none) {
      .everything-that-moves-container:after {
        margin-left: 0rem;
      }
    }
  }
}

.footer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $s-md;
}

.footer-header__logo {
  height: $height-logo-toyota;
}

.everything-that-moves-container {
  position: relative;

  width: $everything-that-moves-logo-width;
  height: $everything-that-moves-logo-height;

  &:before,
  &:after {
    position: absolute;
    width: 100vw;
    height: $everything-that-moves-logo-border;
    background-color: $c-base-white;
    content: '';
  }

  &:before {
    right: 100%;
  }

  &:after {
    bottom: $everything-that-moves-border-right-position;
  }
}

/* CONTENT
========================================================================== */
.footer-content {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include gt-tablet {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

.footer-content__content {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include phone {
    margin-bottom: $s-s;
    gap: $s-s;
  }

  @include gt-tablet {
    flex-direction: row;
    align-items: center;
  }
}

.content__links-and-seal-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $s-md;

  @include phone {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: $s-s;
  }
}

.container_seal_logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $s-s;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: $s-zs;
  }
}

.reclame-aqui__logo {
  height: $height-logo-seal;
}

.footer-disclaimer {
  margin-top: $s-lg;
  color: $c-base-white;
  font-size: $text-size-micro;
  line-height: $text-line-height-default;
}

.footer-links-tasa {
  display: grid;
  gap: $s-zs;
}

/* IBAMA LOGO
========================================================================== */
.ibama-container {
  display: flex;
  align-items: center;
  gap: $s-s;
}

.ibama-container__lede {
  color: $c-base-white;
  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
}

/* NAVIGATION
========================================================================== */
.footer-links {
  display: flex;
  flex-direction: column;
  gap: $s-s;

  @include gt-tablet {
    flex-direction: row;
    gap: $s-lg;
  }
}

.anchor-link {
  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
}

/* SOCIAL LINKS
========================================================================== */
.social-links {
  display: flex;
  gap: $s-md;
}

.social-links__link {
  opacity: 1;
  transition: opacity ease $basic-transition;

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: opacity ease $basic-transition;
  }
}

/* SECONDARY NAVIGATION
========================================================================== */
.links-list {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include gt-tablet {
    flex-direction: row;
    gap: $s-lg;
  }
}
