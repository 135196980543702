
/******************************************
 * Components global scope rules
 ******************************************/
 body {
  &[data-menu-open] {
    overflow: hidden;
  }

  &[data-modal-open] {
    overflow: hidden;
  }

  &[data-loading] {
    overflow: hidden;
  }
}

/*
 * CONTENT
 */
[data-content-wrapper],
.content-wrapper {
  position: relative;
  padding: 0 $grid-padding;
  width: 100%;

@include gt-tablet {
  margin: auto;
  padding: 0 $grid-padding-desktop;
  max-width: $grid-width-desktop;
} 
}

[data-content-wrapper="small"] {
  max-width: 71rem;
}

.opblock-section-header {
  background-color: #2225 !important;
  color: #fff !important;
}

.swagger-ui .model-box-control:focus,
.swagger-ui .models-control:focus,
.swagger-ui .opblock-summary-control:focus {
  outline: solid 2px #999 !important;
}

// !!IMPORTANT!! Creator dynamic content start
// Defining the REM size
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  color: $c-base-text;
}

a {
  color: $c-base-text;

  // &:visited {
  //   color: undefined;
  // }
}
// Defining typographies
// !!IMPORTANT!! End of Creator dynamic content

//Global Helpers:
.visuallyhidden, [data-visuallyhidden] {
  @include visuallyhidden();
}

/* TEXT
========================================================================== */
[data-uppercase] {
  text-transform: uppercase !important;
}
[data-bold] {
  font-weight: 500 !important;
}

[data-font-regular] {
  font-weight: 400 !important;
}

[data-center-elements] {
  text-align: center;
}

/* GRID
========================================================================== */
[data-grid=true] {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: $grid-gutter; 

  @include gt-tablet{        
    grid-template-columns: repeat(12,1fr);            
  }  

}

[data-grid-list="2"] {       
  @include gt-tablet{        
    grid-template-columns: repeat(2,1fr);    
  }
}

[data-grid-list="3"] {  
  @include gt-tablet{        
    grid-template-columns: repeat(3,1fr);    
  }
}

[data-grid-full-width="true"] {
  @include gt-tablet { 
    grid-column: 1/-1;
  }
}

[data-grid-recess="1/3"] {
  @include gt-tablet {
    grid-column: 3/11;
  }
}

/* VISIBILITY
========================================================================== */
[data-hide="mobile"] {
  
  @include phone {
    display: none;  
  }

}

[data-hide="desktop"] {
  @include gt-tablet {
    display: none;
  }
}