@import 'fonts';

// variables only
@import 'utils/styles/_colors';
@import 'utils/styles/_sizes';
@import 'utils/styles/_shapes';
@import 'utils/styles/_mixins';
// global selectors
@import 'utils/styles/typography';
@import 'utils/styles/css-vars-color';
@import 'utils/styles/_globals';

*, *::before, *::after {
  box-sizing: border-box;
}

//FULL RESET
* {
  margin: 0;
  padding: 0;

  scroll-behavior: smooth;  
}

html, body {
  min-height: 100vh;

  line-height: 1;

  background-color: #fff;
  text-decoration-skip-ink: auto;

  font-smoothing: antialiased;
  webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;

  overflow-x: hidden;
  
}

#root, .root, #__next[data-reactroot] {
  height: 100vh;
  overflow: auto;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

svg {
  &[data-icon="1"] {
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-exit {
  display: none;
}

a {
  text-decoration: none;
  text-decoration-skip: all;

  &:hover, &:focus {
    text-decoration: underline;
  }

  // &[disabled] {
  //   opacity: .5;
  //   filter: grayscale(1);
  //   text-decoration: line-through;
  // }
}


//ELEMENTS RESET
ul, ol {
  list-style: none;
}

button {
  font-family: Toyota, Arial, Helvetica, sans-serif;
  cursor: pointer;
  background-color: transparent;
}

input {
  font-family: Toyota, Arial, Helvetica, sans-serif;
}